import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '@/constants/colors';
import DateHelper from '@/helpers/dateHelper';
import CartItemLine from './CartItemLine';
import Breakpoints from '@/constants/breakpoints';

const useStyles = createUseStyles(
  {
    root: {},
    item: {
      backgroundColor: Colors.whiteOne,
      border: `1px solid ${Colors.blackOne}`,
      // margin: '60px 0 0',
      padding: '60px',
      position: 'relative',
      marginBottom: '1em',
    },
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      item: { padding: '20px' },
    },
    removeItem: {
      position: 'absolute',
      right: '10px',
      top: '0px',
      '& .icon': {
        cursor: 'pointer',
        // font-family: "Work-Sans", sans-serif;
        fontSize: '1rem',
        padding: '17px 0',
        position: 'relative',
      },
    },
    location: {
      color: Colors.grayThree,
      display: 'block',
      // font-family: "Work-Sans", sans-serif;
      fontSize: '1rem',
      letterSpacing: '1.4px',
      lineHeight: 'normal',
      margin: '0 0 8px',
      textTransform: 'uppercase',
    },
    shortDescription: {
      display: 'block',
      // font-family: "Canela-Regular", serif;
      fontSize: '1.2rem',
      lineHeight: 1.5,
      margin: 0,
    },
    dates: {
      display: 'block',
      // font-family: "Work-Sans", sans-serif,
      fontSize: '1rem',
      lineHeight: 1.5,
      margin: '8px 0 8px',
    },
    guest: {
      display: 'block',
      // font-family: "Work-Sans", sans-serif,
      fontSize: '1rem',
      lineHeight: 1.5,
      margin: '8px 0 26px',
    },
    lines: {
      display: 'block',
      '& :nth-child(1), :nth-child(3)': { margin: '0px 0 0!important' },
    },
    feesTaxes: {
      marginTop: '5px !important',
    },
  },
  { name: 'CartItem' },
);

const CartItem = (props) => {
  const classes = useStyles();
  const item = props.item;
  const readOnly = props.readOnly;

  return (
    <>
      <div className={classes.item}>
        <div className={classes.removeItem} onClick={() => props.onRemoveItem(item)}>
          {!readOnly && <div className="icon">Remove</div>}
        </div>
        <h3 className={classes.location}>{item.roomType.property.name}</h3>
        <h1 className={classes.shortDescription}>{item.roomType.name}</h1>
        <p className={classes.dates}>
          {DateHelper.defaultFormat2(item.dateRateList[0].date)} -{' '}
          {DateHelper.defaultFormat2(
            DateHelper.addDay(item.dateRateList[item.dateRateList.length - 1].date),
          )}
        </p>
        <p className={classes.guest}>
          {item.people} {item.people == 1 ? 'guest' : 'guests'}
        </p>
        <div className={classes.lines}>
          <CartItemLine title="Total" description={`$${item.chargesAndTaxes.totalCharges}`} />
          <hr></hr>
          {item.dateRateList.map((dateRate, idx) => (
            <CartItemLine
              key={idx}
              title={DateHelper.defaultFormat3(dateRate.date)}
              description={`$${dateRate.rate}`}
            />
          ))}
          <hr></hr>
          <CartItemLine
            title="Resort Fees"
            description={`$${item.chargesAndTaxes.fees}`}
            className={classes.feesTaxes}
          />
          {item.chargesAndTaxes.cleaningFees !== null &&
            item.chargesAndTaxes.cleaningFees !== 0 && (
              <CartItemLine
                title="Cleaning Fees"
                description={`$${item.chargesAndTaxes.cleaningFees}`}
                className={classes.feesTaxes}
              />
            )}
          {item.chargesAndTaxes.extraPeopleFees !== null &&
            item.chargesAndTaxes.extraPeopleFees !== 0 && (
              <CartItemLine
                title="Extra Guest Fees"
                description={`$${item.chargesAndTaxes.extraPeopleFees}`}
                className={classes.feesTaxes}
              />
            )}
          <CartItemLine
            title="Taxes"
            description={`$${item.chargesAndTaxes.taxes}`}
            className={classes.feesTaxes}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(CartItem);
